<template>
  <div >
    <div v-if="num == 0 && teate === false">
      <div class="flex align_center">
        <div class="icon"></div>
        <div class="service">服务专员信息</div>
      </div>
      <div class="flex align_center">
        <img src="../../assets/image/icon_commone.png" class="img_icon" alt="" />
        <div class="img_title">
          您的服务专员信息未进行实名认证，请点击下面按钮进行认证~~
        </div>
      </div>
      <img @click="claimsta()" src="../../assets/image/specialistInformationimg.png" class="img" alt="" />
    </div>

    <div v-else-if="num == 1 && teate === false">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务专员信息</div>
      </div>
      <div class="dis align_center">
        <img src="../../assets/image/icon_commone.png" class="img_icon" alt="" />
        <div class="img_title">您的个人信息正在进行审核，请等待~~</div>
      </div>
      <div>
        <img src="../../assets/image/claimawait.png" class="img" alt="" />
      </div>
    </div>

    <div v-else-if="num == 2 && teate === false">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务专员信息</div>
      </div>
      <div class="dis align_center pad_20">
        <div>
          <!-- <img
            src="../../assets/image/topimg.png"
            style="width: 119px; height: 118px"
            alt=""
          /> -->
          <img :src="currency.img" style="width: 119px; height: 118px" alt="" />
        </div>
        <div class="marl_20">
          <div class="servicetext size_26">{{ currency.name }}</div>
          <div class="flex" style="padding: 10px 0px">
            <div class="servicetext size_18" v-if="currency.audit_status === 2">
              企业认领：已认领
            </div>
            <div class="flex marl_20">
              <div class="servicetext size_18">个人基本信息：</div>
              <div class="servicetext size_18 col_618" @click="amend()">
                点击修改
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex servicetext size_18">
              当前服务币余额：
              <div class="col_D3D">{{ currency.currency }}</div>
              个
            </div>
            <!-- <el-button type="text" @click="open"
              >点击打开 Message Box</el-button
            > -->
            <div class="cash" @click="withdraw()">点击提现</div>
            <!-- <el-button class="cash">点击提现</el-button> -->
          </div>
        </div>
      </div>

      <el-dialog title="个人基本信息" :visible.sync="dialogVisible" width="600px" v-loading="loading2">
        <div>
          <div>服务专员头像</div>

          <div class="pad_20">
            <el-upload :file-list="uploadList" ref="uploadfiles2" list-type="picture-card" class="avatar-uploader"
              action="#" accept=".jpg,.jpeg,.png" :multiple="true" :limit="1" name="file2" :auto-upload="true"
              :http-request="httpreq4" :before-upload="changeUpload1" :on-exceed="handleExceed"
              :on-remove="handleRemove2">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>

          <div>服务专员介绍</div>
          <el-input v-model="service_inp" type="textarea" :rows="5" placeholder="请输入介绍内容"></el-input>
        </div>

        <div>
          <el-button style="
              color: #fefefe;
              background-color: #ff6618;
              width: 137px;
              height: 50px;
              margin-top: 20px;
            " @click="save()" type="text">
            确定保存
          </el-button>
          <el-button style="
              color: #fefefe;
              background-color: #cfcfcf;
              width: 137px;
              height: 50px;
              margin-top: 20px;
            " type="text" @click="cancel()">
            取消
          </el-button>
        </div>
      </el-dialog>
    </div>
    <div v-else-if="num == 3 && teate === false">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务专员信息</div>
      </div>
      <div class="dis align_center">
        <img src="../../assets/image/icon_commone.png" class="img_icon" alt="" />

        <div class="img_title">
          您的服务专员实名认证信息未通过，请点击下面按钮重新进行认证~~
        </div>
      </div>

      <img src="../../assets/image/specialistInformationimg.png" class="img" alt="" @click="claimsta()" />
    </div>

    <div v-if="teate === true" v-loading="loading">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务专员实名认证</div>
      </div>
      <el-form :model="ruleForm" ref="ruleForm" label-width="120px" :rules="rules" style="padding-top: 20px">
        <el-form-item label="姓名 " required prop="name">
          <el-input class="width_500" placeholder="请输入姓名" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="性别 " required prop="sex">
          <!-- <el-radio v-model="ruleForm.sex" label="0">男</el-radio>
          <el-radio v-model="ruleForm.sex" label="1">女</el-radio> -->
          <el-radio-group v-model="ruleForm.sex">
            <el-radio label="0">男</el-radio>
            <el-radio label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="民族 " required prop="nation">
          <el-input class="width_500" placeholder="请输入民族" v-model="ruleForm.nation"></el-input>
        </el-form-item>
        <el-form-item label="学历 " required prop="education">
          <el-input class="width_500" placeholder="请输入学历" v-model="ruleForm.education"></el-input>
        </el-form-item>
        <el-form-item label="联系电话 " required prop="phone">
          <el-input class="width_500" placeholder="请输入联系电话" v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱 " required prop="Email">
          <el-input class="width_500" placeholder="请输入邮箱" v-model="ruleForm.Email"></el-input>
        </el-form-item>
        <el-form-item label="工作单位 " required prop="workunit">
          <el-input class="width_500" placeholder="请输入工作单位" v-model="ruleForm.workunit"></el-input>
        </el-form-item>
        <el-form-item label="擅长领域 " required prop="expertise">
          <el-checkbox v-model="ruleForm.expertise" :label="item.id" v-for="(item, index) in options" :key="index">{{
            item.name
          }}</el-checkbox>
          <!-- <el-radio v-model="ruleForm.expertise" label="2">人才服务</el-radio>
          <el-radio v-model="ruleForm.expertise" label="3">管理咨询</el-radio> -->
        </el-form-item>
        <el-form-item label="开户行 " required prop="bank">
          <el-input class="width_500" placeholder="请输入开户行" v-model="ruleForm.bank"></el-input>
        </el-form-item>
        <el-form-item label="卡号 " required prop="card">
          <el-input class="width_500" placeholder="请输入卡号" v-model="ruleForm.card"></el-input>
        </el-form-item>

        <el-form-item label="本人职业照片 (注：请上传 1:1 个人照片)" required>

          <el-upload :file-list="uploadList2" ref="uploadfiles2" list-type="picture-card" class="avatar-uploader"
            action="#" accept=".jpg,.jpeg,.png" :multiple="true" :limit="1" name="file2" :auto-upload="true"
            :http-request="httpreq1" :before-upload="changeUpload2" :on-exceed="handleExceed"
            :on-remove="handleRemove2">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="身份证正面 (注：请上传带有国徽图案的身份证照片)" required>
          <el-upload :file-list="uploadList3" ref="uploadfiles2" list-type="picture-card" class="avatar-uploader"
            action="#" accept=".jpg,.jpeg,.png" :multiple="true" :limit="1" name="file2" :auto-upload="true"
            :http-request="httpreq2" :before-upload="changeUpload3" :on-exceed="handleExceed"
            :on-remove="handleRemove2">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="身份证反面 (注：请上传带有人像图案的身份证照片)" required>
          <el-upload :file-list="uploadList4" ref="uploadfiles2" list-type="picture-card" class="avatar-uploader"
            action="#" accept=".jpg,.jpeg,.png" :multiple="true" :limit="1" name="file2" :auto-upload="true"
            :http-request="httpreq3" :before-upload="changeUpload4" :on-exceed="handleExceed"
            :on-remove="handleRemove2">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>

      <el-button style="
          width: 137px;
          height: 50px;
          background: #ff6618;
          border-radius: 10px;
          color: #fff;
        " @click="getsub()">确认提交</el-button>

      <el-button style="
          width: 137px;
          height: 50px;
          background: #cfcfcf;
          border-radius: 10px;
          color: #fff;
        " @click="teate = false">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
      teate: false,
      img1: "",
      img2: "",
      loading:false,
      loading2:false,
      img3: "",
      img4: "",
      loads: [],
      loads2: [],
      loads3: [],
      loads4: [],
      options: "",
      img1login: false,
      img2login: false,
      img3login: false,
      dialogVisible: false,
      service_inp: "",
      uploadList: [],
      uploadList2: [],
      uploadList3: [],
      uploadList4: [],
      currency: {},
      ruleForm: {
        name: "",
        sex: "",
        nation: "",
        education: "",
        phone: "",
        Email: "",
        workunit: "",
        expertise: [],
        bank: "",
        card: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { required: true, message: "姓名不能为空", trigger: "change" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        nation: [
          { required: true, message: "请输入民族", trigger: "blur" },
          { required: true, message: "民族不能为空", trigger: "change" },
        ],
        education: [
          { required: true, message: "请输入学历", trigger: "blur" },
          { required: true, message: "学历不能为空", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
        Email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { required: true, message: "邮箱不能为空", trigger: "change" },
        ],
        workunit: [
          { required: true, message: "请输入工作单位", trigger: "blur" },
          { required: true, message: "工作单位不能为空", trigger: "change" },
        ],
        expertise: [
          { required: true, message: "请选择擅长领域", trigger: "blur" },
          { required: true, message: "擅长领域不能为空", trigger: "change" },
        ],
        bank: [
          { required: true, message: "请输入开户行", trigger: "blur" },
          { required: true, message: "开户行不能为空", trigger: "change" },
        ],
        card: [
          { required: true, message: "请输入卡号", trigger: "blur" },
          { required: true, message: "卡号不能为空", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getcatelist();
    this.info();
    this.postcurrency();
  },
  methods: {
    // 体现
    withdraw() {
      this.$prompt("请输入提现金额", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          console.log(value > 0);
          console.log(value < this.currency.currency);
          if (value > 0 && value < this.currency.currency) {
            let data = {
              token: localStorage.eleToken,
              num: value,
            };
            console.log(data, "data");
            this.$post("apply_add", data).then((res) => {
              this.$message({
                message: res.msg,
                type: "warning",
                duration: 1500,
              });
              this.postcurrency();
            });
          } else {
            this.$message({
              message: "服务币不足",
              type: "warning",
              duration: 1500,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    save() {
     
      this.loading2 = true
      let formdata = new FormData();
      formdata.append("file", this.loads4);
      this.$post("upload_img", formdata).then((res) => {
        this.img4 = res.address;
        this.commissioner_save()
        console.log(res, "res");
      });


    },

    commissioner_save() {
      let data = {
        token: localStorage.eleToken,
        img: this.img4,
        introduce: this.service_inp,
      };
      this.$post("commissioner_save", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: "修改成功",
          type: "success",
          duration: 1500,
        });
        this.loading2 = false
        this.postcurrency();
        this.dialogVisible = false;
      });
    },

    cancel() {
      this.dialogVisible = false;
    },
    amend() {
      this.dialogVisible = true;
    },
    claimsta() {
      this.teate = true;
      console.log(this.teate, "this.teate");
    },
    httpreq1(load) {

      this.loads = load.file
      this.img1login = true;
      console.log(this.loads, 'loads')


    },

    upload_img1() {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("file", this.loads);
        this.$post("upload_img", formdata).then((res) => {

          this.img1 = res.address;
          resolve();
        });
      })



    },

    httpreq2(load) {

      this.loads2 = load.file
      console.log(this.loads2, 'loads2')
      this.img2login = true;

    },

    upload_img2() {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("file", this.loads2);
        this.$post("upload_img", formdata).then((res) => {
          this.img2 = res.address;
          resolve();
        });
      })
    },
    httpreq3(load) {
      this.loads3 = load.file
      console.log(this.loads3, 'loads3')
      this.img3login = true;

    },

    upload_img3() {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("file", this.loads3);
        this.$post("upload_img", formdata).then((res) => {
          this.img3 = res.address;
          resolve();
        });
      })
    },
    httpreq4(load) {
      this.loads4 = load.file
      console.log(this.loads4, 'loads4')


    },
    handleRemove2() {
      this.uploadList1 = [];
      this.finance_trade = "";
    },
    handleExceed() {
      this.$message({
        message: "最大上传数量为1张!",
        type: "warning",
        duration: 1500,
      });
    },

    changeUpload1(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        this.uploadList = []
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },
    changeUpload2(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        this.uploadList2 = []
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },
    changeUpload3(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        this.uploadList3 = []
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },


    changeUpload4(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        this.uploadList4 = []
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },
    getsub() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {

      if (this.img1login === false) {
        this.$message({
          message: "请上传本人职业照片",
          type: "warning",
          duration: 1500,
        });
      } else if (this.img2login === false) {
        this.$message({
          message: "请上传身份证正面",
          type: "warning",
          duration: 1500,
        });
      } else if (this.img3login === false) {
        this.$message({
          message: "请上传身份证反面",
          type: "warning",
          duration: 1500,
        });
      } else {
        this.loading = true
      Promise.all([this.upload_img1(), this.upload_img2(), this.upload_img3()]).then((res) => {

        console.log("res==>", res);
        this.commissioner_upd()
        this.loading = false
      }).catch((error) => {

        console.log("error==>", error);

      })



      }
        }
      });
    },

    commissioner_upd() {
      let data = {
        token: localStorage.eleToken,
        phone: this.ruleForm.phone,
        name: this.ruleForm.name,
        sex: this.ruleForm.sex,
        nation: this.ruleForm.nation,
        education: this.ruleForm.education,
        email: this.ruleForm.Email,
        unit: this.ruleForm.workunit,
        pro_cate_ids: this.ruleForm.expertise,
        bank: this.ruleForm.bank,
        bank_number: this.ruleForm.card,
        img: this.img1,
        id_card_front: this.img2,
        id_card_back: this.img3,
      };
      console.log(data, "data");
      this.$post("commissioner_upd", data).then((res) => {
        // console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.info();
        this.postcurrency();
        this.$router.go(0);
      });
    },


    //检查身份
    info() {
      let data = {
        token: localStorage.eleToken,
      };

      this.$get("commissioner_info", data).then((res) => {
        this.num = res.result.audit_status;
        console.log(this.num, " this.num");
      });
    },

    //查看专员信息
    postcurrency() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$post("commissioner_currency", data).then((res) => {
        console.log(res, "res");
        this.currency = res.result;
      });
    },

    //获取擅长领域列表
    getcatelist() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result;
        console.log(this.options, "this.options");
      });
    },
  },
};
</script>

<style scoped>
/* @import url("./specialistInformation.css"); */
.width_500 {
  width: 500px;
}

.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}

.img_title {
  height: 30px;
  font-size: 18px;
  font-family: Source Han Sans SC Regular, Source Han Sans SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}

.img {
  position: relative;
  /* left: 300px; */
  margin: 0 auto;
  top: 60px;
  display: block;
}

.img_icon {
  width: 18px;
  height: 16px;
}

.servicetext {
  height: 30px;
  font-family: Source Han Sans SC Regular, Source Han Sans SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 30px;
}

.col_D3D {
  color: #ec3d3d;
}

.col_618 {
  color: #ff6618;
}

.cash {
  width: 98px;
  height: 30px;
  background: rgb(255, 221, 204);
  border-radius: 10px;
  color: rgb(255, 100, 23);
  line-height: 30px;
  text-align: center;
  margin-left: 20px;
  padding: inherit;
  cursor: pointer;
}
</style>
